import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Layout from '../components/layout';
import Contact from '../components/Contact';

import heroStyles from '../components/hero.module.css';

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulCaseStudies');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const heroImage = get(this.props, 'data.contentfulCaseStudies.heroImage.file.url');

    return (
      <Layout location={this.props.location}>
        <Helmet title={`${post.title} | ${siteTitle}`} />
        <div className={heroStyles.hero}>
          <img className={heroStyles.heroImage} alt={post.title} src={heroImage} />
        </div>
        <div className="container blog-wrapper">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 section">
              <h1 className="section--title">{post.title}</h1>
              <small
                style={{
                  display: 'block',
                  marginBottom: '20px'
                }}
              >
                {post.publishDate}
              </small>
              <div
                dangerouslySetInnerHTML={{
                  __html: post.body.childMarkdownRemark.html
                }}
              />
            </div>
          </div>
        </div>
        <Contact />
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCaseStudies(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
